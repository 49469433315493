import { Component, Vue } from 'vue-property-decorator'
import Prestations from '@/views/Patients/Prescriptions/Prestations/Prestations.vue'
import { prescriptionStatusTypes } from '../../Dossier/Constants'
import { PrescriptionModel } from '@/models/prescriptions-model'
import { Subscription } from 'rxjs'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Commons from '@/components/shared/Helpers/commons'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { AxiosResponse } from 'axios'

@Component({
  components: {
    Prestations,
    Alert,
    ValidationObserver,
    ValidationProvider
  }
})

export default class Prescription extends Vue {
  private prescriptionService = PatientPrescriptionService.getInstance()
  private subscription!: Subscription
  private selectedDossier!: dossierPatientModel

  public status = prescriptionStatusTypes
  public apiURL = process.env.VUE_APP_API_URL
  public prescription: PrescriptionModel = {}
  public errorMessages: string[] = []
  public professionalDoctorsList: { id: number; fullname: string }[] = []
  public isSaving = false
  public isEdit = false
  public searchLoading = false
  public showDialog = false
  public showConfirm = false
  public dossierId!: string
  public prescriptionId = ''

  public mounted () {
    this.dossierId = this.$route.params.dossierId
    this.prescriptionId = this.$route.params.id
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
      this.selectedDossier = JSON.parse(cachedDossier)
    } else {
      this.$router.push('/patient')
    }
    this.subscription = this.prescriptionService.prescriptionSelected$.subscribe(selectedPrescription => {
      this.prescription = selectedPrescription
      if (this.prescriptionId === 'new' && this.dossierId) {
        this.prescription.dossierId = this.dossierId
      }
      if (!this.prescription.id && this.prescriptionId !== 'new') {
        this.$router.push({ name: 'PatientPrescriptions' })
      }
      this.prescription.date = Commons.FormatDateForInputField(new Date(this.prescription.date!))
      this.isEdit = !!selectedPrescription.id
    })
    this.ensureUserNavTitle()
    this.getDoctors()
  }

  private ensureUserNavTitle () {
    if (this.selectedDossier.guid) {
      Commons.updateDossierName()
    }
  }

  private async getDoctors () {
    this.searchLoading = true
    const response = await this.prescriptionService.getDoctors(this.prescription.prescribedDoctorId)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.searchLoading = false
      })
    this.professionalDoctorsList = response as { id: number; fullname: string }[]
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      const results = await this.prescriptionService.AddUpdatePrescription(this.dossierId, this.prescription)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if ((results as AxiosResponse<string>).status === 200) {
        this.prescriptionId = (results as AxiosResponse<string>).data
        if (this.prescription.id) {
          this.$router.push({ name: 'PatientPrescriptions' })
        } else {
          this.prescription.id = this.prescriptionId
        }
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public async goToPrescriptions () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    observer.reset()
    this.errorMessages = []
    this.$router.go(-1)
  }

  // ==> prestations
  public addPrestation () {
    this.prescriptionService.updateprestationDialogModelSource(true)
    this.prescriptionService.updatePrescriptionSelected(this.prescription)
  }

  public destroyed () {
    this.subscription.unsubscribe()
    this.prescriptionService.updatePrescriptionSelected({ date: Commons.GetTodayFormatted(), statusId: 1 })
  }
}
